import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4f4cd49b = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _326ec8b5 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _424b6862 = () => interopDefault(import('../pages/inspire.vue' /* webpackChunkName: "pages/inspire" */))
const _18b54171 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _315cf854 = () => interopDefault(import('../pages/reservation.vue' /* webpackChunkName: "pages/reservation" */))
const _17e13eb3 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))
const _4a4cd9c0 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _6345a896 = () => interopDefault(import('../pages/account/notification.vue' /* webpackChunkName: "pages/account/notification" */))
const _5cbd7b7a = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _4d42ae65 = () => interopDefault(import('../pages/account/password.vue' /* webpackChunkName: "pages/account/password" */))
const _9b6c3958 = () => interopDefault(import('../pages/reservations/history.vue' /* webpackChunkName: "pages/reservations/history" */))
const _5cf7c1e4 = () => interopDefault(import('../pages/reservations/list.vue' /* webpackChunkName: "pages/reservations/list" */))
const _76f4d24e = () => interopDefault(import('../pages/service/contact.vue' /* webpackChunkName: "pages/service/contact" */))
const _59943168 = () => interopDefault(import('../pages/service/coupon.vue' /* webpackChunkName: "pages/service/coupon" */))
const _62942815 = () => interopDefault(import('../pages/service/news.vue' /* webpackChunkName: "pages/service/news" */))
const _9a5dd2d4 = () => interopDefault(import('../pages/service/notifications.vue' /* webpackChunkName: "pages/service/notifications" */))
const _63be4494 = () => interopDefault(import('../pages/service/privacy.vue' /* webpackChunkName: "pages/service/privacy" */))
const _5a7ad9b5 = () => interopDefault(import('../pages/service/terms.vue' /* webpackChunkName: "pages/service/terms" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _4f4cd49b,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/forget_password/",
    component: _326ec8b5,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/inspire/",
    component: _424b6862,
    pathToRegexpOptions: {"strict":true},
    name: "inspire"
  }, {
    path: "/login/",
    component: _18b54171,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reservation/",
    component: _315cf854,
    pathToRegexpOptions: {"strict":true},
    name: "reservation"
  }, {
    path: "/reset_password/",
    component: _17e13eb3,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/signup/",
    component: _4a4cd9c0,
    pathToRegexpOptions: {"strict":true},
    name: "signup"
  }, {
    path: "/account/notification/",
    component: _6345a896,
    pathToRegexpOptions: {"strict":true},
    name: "account-notification"
  }, {
    path: "/account/overview/",
    component: _5cbd7b7a,
    pathToRegexpOptions: {"strict":true},
    name: "account-overview"
  }, {
    path: "/account/password/",
    component: _4d42ae65,
    pathToRegexpOptions: {"strict":true},
    name: "account-password"
  }, {
    path: "/reservations/history/",
    component: _9b6c3958,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-history"
  }, {
    path: "/reservations/list/",
    component: _5cf7c1e4,
    pathToRegexpOptions: {"strict":true},
    name: "reservations-list"
  }, {
    path: "/service/contact/",
    component: _76f4d24e,
    pathToRegexpOptions: {"strict":true},
    name: "service-contact"
  }, {
    path: "/service/coupon/",
    component: _59943168,
    pathToRegexpOptions: {"strict":true},
    name: "service-coupon"
  }, {
    path: "/service/news/",
    component: _62942815,
    pathToRegexpOptions: {"strict":true},
    name: "service-news"
  }, {
    path: "/service/notifications/",
    component: _9a5dd2d4,
    pathToRegexpOptions: {"strict":true},
    name: "service-notifications"
  }, {
    path: "/service/privacy/",
    component: _63be4494,
    pathToRegexpOptions: {"strict":true},
    name: "service-privacy"
  }, {
    path: "/service/terms/",
    component: _5a7ad9b5,
    pathToRegexpOptions: {"strict":true},
    name: "service-terms"
  }, {
    path: "/",
    redirect: "/reservation/",
    name: "index"
  }, {
    path: "*",
    redirect: "/reservation/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
